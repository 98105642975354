const $cover = document.querySelector('.cover');
const coverClassList = ['cover--default', 'cover--overflow', 'cover--full'];
let currentCoverClass;
let elemInfo = document.createElement('span');
elemInfo.classList.add('qa-node-info');

if ($cover) {
  $cover.addEventListener('click', function (event) {
    if (!currentCoverClass) {
      currentCoverClass = 0;
      addElemInfoToNode($cover);
    }

    $cover.classList.remove(coverClassList[currentCoverClass]);
    currentCoverClass++;
    let $info = $cover.querySelector('.qa-node-info');
    $info.innerHTML = `Cover kép: ${coverClassList[currentCoverClass]}`;
    $cover.classList.add(coverClassList[currentCoverClass]);

    if (currentCoverClass >= coverClassList.length) {
      currentCoverClass = 0;
      $info.innerHTML = `Cover kép: ${coverClassList[currentCoverClass]}`;
    }
  });
}

function addElemInfoToNode(node) {
  if (!node.contains(elemInfo)) {
    node.appendChild(elemInfo);
  }
}